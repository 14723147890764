
.CategoryList {
  .selected {
    font-weight: bold;
    text-decoration: underline;
  }

  .deactivated {
    color: #bababa
  }
}