@import '@elastic/eui/src/themes/amsterdam/colors_light';
@import '@elastic/eui/src/themes/amsterdam/globals';
@import '@elastic/eui/src/themes/amsterdam/theme_light';


:root {
  --font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, sans-serif;
  --font-weight: 400;
  --font-weight-bold: 700;

  --color-primary: #000;
  --color-background: #fff;
  --color-background-weak: #f6f6f6;
  --color-text: #333;
  --color-text-light: #787878;
  --color-accent: #0070f3;

  --grid-margin: 5vw;

  --color-text-white: #fff;
  --border-color: #e9e9e9;
  --border: 1px solid var(--border-color);

  --radius: 8px;
  --navbar-height: 64px;

  --size-small: 24px;
  --size-medium: 32px;
  --size-large: 40px;
  --size-huge: 64px;

  --grid-margin: 1.5rem;

  --space-05: 0.125rem;
  --space-1: 0.25rem;
  --space-2: 0.5rem;
  --space-3: 0.75rem;
  --space-4: 1rem;
  --space-5: 1.25rem;
  --space-6: 1.5rem;

  --shadow: 0 0 0.5rem rgba(0, 0, 0, 0.05);

  --space-1-minus: calc(var(--space-1) * -1);

  --space-1-minus: calc(var(--space-1) * -1);

  --layout-width-narrow: 400px;
  --layout-width-regular: 880px;
  --layout-width-wide: 1320px;

  --gray0: #fff;
  --gray100: #f5f6f5;
  /* --gray100: #fafbfa; */
  --gray150: #eff1ef;
  /* --gray100: #f2f5f2; */
  --gray200: #e4e9e7;

  --gray400: #979d9a;
  --gray800: #343c38;
  --gray800: #3d4049;

  --green100: rgba(10, 160, 80, 0.1);
  --green100: rgba(10, 160, 80, 0.075);
  --green800: #1b995c;
  --red100: rgba(243, 68, 97, 0.06);
  --red800: #f02849;
  --yellow800: gold;

  --color-white: var(--gray0);
  --color-black: #000;

  // --color-primary: var(--green800);
  --color-primary-light: null;
  --color-secondary: var(--yellow800);
  // --color-accent: var(--yellow800);
  --color-warning: var(--red800);
  --color-danger: var(--red800);

  --color-google: #fff;
  --color-facebook: hsl(214, 89%, 52%);
  --color-twitter: rgb(29, 161, 242);
  --color-naver: #03cf5d;
  --color-kakao: #ffdf00;
  --color-newneek: #fb7800;
  --color-newneek-gray: #ebebeb;
  --color-pinterest: #e60023;
  --color-github: #000;
  --color-instagram: #1c1e21;

  --color-background: var(--gray0);
  --color-background-weak: var(--gray100);
  --color-background-strong: var(--gray800);
  --color-background-darkest: null;
  --color-border: var(--gray200);
  --color-border-hover: var(--gray800);
  --color-text: var(--gray800);
  /* --color-text: #1e1e23; */
  --color-text-light: var(--gray400);

  --color-background-interaction: null;
  --color-background-non-interaction: null;

  /* TODO */
  /* --color-text-weak: var(--gray0); */
  --color-text-strong: var(--gray0);

  --home-color-gradient1: rgb(231, 243, 255);
  --home-color-gradient2: rgb(255, 255, 255);
}
